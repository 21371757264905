import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Button1 from '../../form/Button1'
import TextInput from '../../form/TextInput'
import getAdminTeamMembers from '../../../controllers/settings/adminTeam/getTeamMembers'
import { PriceBreakdown } from './PriceBreakdown'
import { enqueueSnackbar } from 'notistack'
import setRefundFee from '../../../controllers/booking/postTicketing/refund/setRefundFee'

export default function RefundQuote({data,reload}) {
  const [handleOption,setHandleOption] = useState('direct')
  const [details,setDetails] = useState('')
  const [staffs,setStaffs] = useState([])
  const [loadings,setLoadings] = useState({fetchStaff: false})
  const [feeData,setFeeData] = useState({
    penality: data?.fee||0,
    ticketDifference: data?.ticketDifference||0,
  })

  useEffect(() => {
    loadStaff()
  },[data])

  async function loadStaff() {
    setLoadings({...(loadings||{}),fetchStaff: true})
    const res = await getAdminTeamMembers();
    setLoadings({...(loadings||{}),fetchStaff: false})
    if(res.return) {
      setStaffs(res?.data?.data||[])
    }
  }

  async function handleSubmit() {
    const reqBody = {
        refundRequestId: data?._id,
        // changePrice: Number(feeData?.penality||0),
        // ticketDifference: Number(feeData?.ticketDifference||0),
        "refundAmount": Number(feeData?.refundAmount),
        remark: details,
    }
    setLoadings({...loadings,submit: true})
    const res = await setRefundFee(reqBody);
    setLoadings({...loadings,submit: false})

    if(res.return) {
        enqueueSnackbar('Quote Complete.',{variant: 'success'})
        reload && reload()
    } else enqueueSnackbar(res.msg,{variant: 'error'})
  }

  function handlePriceChange(data) {
    setFeeData(data);
  }

  return (
    <div className='flex flex-col gap-4'>
      <div>
        <div className='flex gap-1'>
          <p>Ticket Number: </p><span>{data?.orderId}</span>
        </div>
        <div className='flex gap-1'>
          <p>Refund Request Time: </p><span>{data?.updatedAt ? moment(data?.updatedAt)?.format('YYYY-MM-DD, HH:mm') : ''}</span>
        </div>
      </div>
      <div className='flex justify-end'>
        <div>
          <Button1 variant='text'>Check fare rules</Button1>
        </div>
      </div>

      <PriceBreakdown data={data} callback={handlePriceChange} />

      <TextInput label='Remarks' multiline rows={4} value={details} onChange={(ev) => setDetails(ev.target.value)} />
      
      <div className='flex justify-end'>
        <div>
          <Button1 onClick={handleSubmit}>Confirm</Button1>
        </div>
      </div>
    </div>
  )
}
