import React, { createContext, useEffect, useState } from "react";
import useOrders from "../../hooks/useOrders";
import { enqueueSnackbar, useSnackbar } from "notistack";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { flightStatusMap } from "../../pages/Orders/OrdersData";
import PaymentMethod from "../flight/PaymentMethod";
import Modal1 from "../DIsplay/Modal/Modal1";
import CancelTicket from "../../pages/Orders/CancelTicket";
import CancelOrder from "../../pages/Orders/cancelOrder";
import { isGDSSupplier } from "../../data/flight/supplier/getSupplierName";
import IssueTicket from "../../pages/Orders/IssueTicket";
import TicketVoid from "../../pages/Orders/VoidTicket";
import Rebook from "../flight/book/Rebook";
import moment from "moment";
import { clone } from "../../features/utils/objClone";
import { offerSearchTemp } from "../../data/flight/offerSearchData";
import { encrypt } from "../../features/utils/crypto";
import { setBookingData } from "../../redux/reducers/flight/flightBookingSlice";
import refreshTicket from "../../controllers/booking/refreshTicket";
import { getTestLevel } from "../../utils/testLevel";
import issueTicket from "../../controllers/booking/issueTicket";
import { ManualTicketForm } from "../../pages/Orders/ApproveTicket";
import Button1 from "../form/Button1";
import issueTicketManually from "../../controllers/booking/issueTicketManually";
import approveTicket from "../../controllers/booking/Ticket/approveTicket";
import TextInput from "../form/TextInput";
import denyTicket from "../../controllers/booking/Ticket/denyTicket";
import requestTicketMoreInfo from "../../controllers/booking/Ticket/requestMoreInfo";
import voidTicketRequest from "../../controllers/booking/postTicketing/void/voidTicketRequest";
import approveVoidTicket from "../../controllers/booking/postTicketing/void/approveVoidTicket";
import approveRefundTicket from "../../controllers/booking/postTicketing/refund/approveRefundTicket";
import denyVoidRequest from "../../controllers/booking/postTicketing/void/denyVoidRequest";
import denyRefundRequest from "../../controllers/booking/postTicketing/refund/denyRefundRequest";
import setRefundFee from "../../controllers/booking/postTicketing/refund/setRefundFee";
import refundTicketRequest from "../../controllers/booking/postTicketing/refund/refundTicketRequest";
import approveChangeTicketRequest from "../../controllers/booking/postTicketing/change/approveChangeTicketRequest";
import reissue from "../../controllers/booking/postTicketing/change/reissue";
import denyChangeTicketRequest from "../../controllers/booking/postTicketing/change/denyChangeTicketRequest";
import setChangeFee from "../../controllers/booking/postTicketing/change/setChangeFee";
import { Modal3 } from "../DIsplay/Modal/Modal3";
import TicketManagementFlightOrderDetail from "../../pages/TicketManagement/Flight/TicketManagementFlightOrderDetail";
import { CompareDateChange } from "../modal/ConfirmFlightDateChangeModal";
import AssignDateChange from "./Change/AssignChange";
import AssignChange from "./Change/AssignChange";
import ChangeQuote from "./Change/ChangeQuote";
import AssignRefund from "./Refund/AssignRefund";
import RefundQuote from "./Refund/RefundQuote";


const ActionContext = createContext();

function OrderList({perPage=10,reload}) {
  const { getOrders } = useOrders();
  const [orders,ogData] = getOrders() || [[],[]];

  const [openPayment,setOpenPayment] = useState(false);
  const [openCancelTicket,setOpenCancelTicket] = useState(false);
  const [openCancelOrder,setOpenCancelOrder] = useState(false);
  const [openIssueTicket, setOpenIssueTicket] = useState(false);
  const [openVoidTicket, setOpenVoidTicket] = useState(false);
  const [openRebook, setOpenRebook] = useState(false);
  const [openManualIssue, setOpenManualIssue] = useState(false);
  const [openDeny,setOpenDeny] = useState(false);
  const [openRequestInfo,setOpenRequestInfo] = useState(false);
  const [openDenyVoid,setOpenDenyVoid] = useState(false);
  const [openRefundTicket,setOpenRefundTicket] = useState(false)
  const [openEditRefund,setOpenEditRefund] = useState(false)
  const [openDenyRefund, setOpenDenyRefund] = useState(false);
  const [openApproveRefund, setOpenApproveRefund] = useState(false);
  const [openEditChange, setOpenEditChange] = useState(false);
  const [openDenyChange, setOpenDenyChange] = useState(false);
  const [openApproveTicket,setOpenApproveTicket] = useState(false);
  const [openViewDetail,setOpenViewDetail] = useState(false);
  const [openTicketMoreInfo,setOpenTicketMoreInfo] = useState(false);
  const [openApproveChange,setOpenApproveChange] = useState(false);
  const [openAssignChangeTask,setOpenAssignChangeTask] = useState(false);
  const [openAssignRefund,setOpenAssignRefund] = useState(false);
  const [openUpdateChangeRequest,setOpenUpdateChangeRequest] = useState(false);
  const [openChangeQuote,setOpenChangeQuote] = useState(false);
  const [openRefundQuote,setOpenRefundQuote] = useState(false);

  
  const {ticketsPage:page} = useSelector(state => state.order);
  const offset = ((page||1)-1)*perPage;

  
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParam = new URLSearchParams(location.search);
  
  function handleRebook(data) {
    console.log(data,data?.flights?.at(0)?.departureDate,moment(data?.flights?.at(0)?.departureDate).format('YYYY-MM-DD'))
    const searchObj = clone(offerSearchTemp)
    searchObj.cabinClass = [data?.flights?.at(0)?.cabinClass || searchObj.cabinClass]
    data?.destinations ? 
      searchObj.originDestinations = data?.destinations?.map(obj => ({
        from: obj?.from,
        to: obj?.to,
        departure: {
          date: obj?.departureDate
        },
        date: obj?.departureDate
      }))
    :
      searchObj.originDestinations = data?.flights?.map(obj => ({
        from: obj?.departureLocation,
        to: obj?.arrivalLocation,
        departure: {
          date: moment(obj?.departureDate).format('YYYY-MM-DD')
        },
        date: obj?.departureDate,
      }))
    
    searchObj['destinations'] = searchObj.originDestinations.map(obj => {
      return {
        departureLocation: obj.from?.iata || obj?.from,
        arrivalLocation: obj.to?.iata || obj?.to,
        date: obj?.departure?.date
      };
    })

    searchObj.passengers = {
      adult: data?.travelers?.filter(obj => obj.travelerType === 'ADT')?.length || 0,
      child: data?.travelers?.filter(obj => obj.travelerType === 'CHD')?.length || 0,
      infant: data?.travelers?.filter(obj => obj.travelerType === 'INF')?.length || 0,
    }

    console.log(searchObj)

    let enc = encrypt(JSON.stringify(searchObj));

    dispatch(setBookingData({offer: null,time: null}))

    let referralCode = searchParam.get('refCode');

    window.open(`/order/new/flight/offers?referralCode=${referralCode}&q=${enc}&action=rebook&flightBookingId=${data?._id}`,'_blank');

  }
  async function handleRefresh(id) {
    const res = await refreshTicket(id);
    if(res.return) {
        // enqueueSnackbar('Ticket has been issued.',{variant: 'success'})
        reload && reload()
    } else enqueueSnackbar(res.msg,{variant: 'error'})
  }

  async function handleIssue(id) {
    const reqBody = {
        ticketQueueId: id,
    }
    const res = await issueTicket(reqBody);
    if(res.return) {
        enqueueSnackbar('Ticket has been issued.',{variant: 'success'})
        reload && reload()
    } else enqueueSnackbar(res.msg,{variant: 'error'})
  }

  async function handleApproveVoid(id) {
    const res = await approveVoidTicket(id);
    if(res.return) {
        enqueueSnackbar('Request has been approved.',{variant: 'success'})
        reload && reload()
    } else enqueueSnackbar(res.msg,{variant: 'error'})
  }

  
  return (
    <div>
      <ActionContext.Provider value={{
        reload,
        openPayment, setOpenPayment,
        openCancelTicket, setOpenCancelTicket,
        openCancelOrder, setOpenCancelOrder,
        openIssueTicket, setOpenIssueTicket,
        openVoidTicket, setOpenVoidTicket,
        openRebook, setOpenRebook: handleRebook,
        refreshTicket: handleRefresh,
        issue: handleIssue, manualIssue: setOpenManualIssue,
        approve: setOpenApproveTicket, deny: setOpenDeny,
        requestInfo: setOpenRequestInfo,
        approveVoid: handleApproveVoid, denyVoid: setOpenDenyVoid,
        approveRefund: setOpenApproveRefund, denyRefund: setOpenDenyRefund, setOpenEditRefund, refundTicket: setOpenAssignRefund,
        approveChange: setOpenApproveChange, denyChange: setOpenDenyChange, setOpenEditChange,
        openTicketMoreInfo,setOpenTicketMoreInfo,
        openViewDetail,setOpenViewDetail,
        openApproveTicket,setOpenApproveTicket,
        assignChangeTask: setOpenAssignChangeTask,
        setOpenUpdateChangeRequest,
        setOpenChangeQuote,
        setOpenRefundQuote,
      }}>
        <div className="w-full flex flex-col mt-4 bg-[#F0F6FC] gap-3 p-1">
          {/* {orders?.slice(offset,offset+perPage).map((order, index) => ( */}
          {orders?.slice(0,perPage).map((order, index) => (
            <Order key={index} order={order} og={ogData?.slice(0,perPage)?.at(index)} />
          ))}
        </div>
      </ActionContext.Provider>

      <ActionHandles {...{reload}}
        {...{openPayment,setOpenPayment}} 
        {...{openCancelOrder,setOpenCancelOrder}}
        {...{openCancelTicket,setOpenCancelTicket}}
        {...{openIssueTicket,setOpenIssueTicket}}
        {...{openVoidTicket,setOpenVoidTicket}}
        {...{openRebook,setOpenRebook}}
        {...{openManualIssue, setOpenManualIssue}}
        {...{openDeny,setOpenDeny}}
        {...{openDenyVoid,setOpenDenyVoid}}
        {...{openApproveRefund, setOpenApproveRefund}}
        {...{openDenyRefund,setOpenDenyRefund}}
        {...{openRequestInfo,setOpenRequestInfo}}
        {...{openEditRefund,setOpenEditRefund}}
        {...{openRefundTicket,setOpenRefundTicket}}
        {...{openDenyChange, setOpenDenyChange}}
        {...{openEditChange, setOpenEditChange}}
        {...{openTicketMoreInfo,setOpenTicketMoreInfo}}
        {...{openViewDetail,setOpenViewDetail}}
        {...{openApproveTicket,setOpenApproveTicket}}
        {...{openApproveChange,setOpenApproveChange}}
        {...{openAssignChangeTask,setOpenAssignChangeTask}}
        {...{openUpdateChangeRequest,setOpenUpdateChangeRequest}}
        {...{openChangeQuote,setOpenChangeQuote}}
        {...{openRefundQuote,setOpenRefundQuote}}
        {...{openAssignRefund,setOpenAssignRefund}}
        {...{}}
       />

    </div>
  );
}

function ActionHandles({
  reload,
  openPayment, setOpenPayment,
  openCancelTicket, setOpenCancelTicket,
  openCancelOrder, setOpenCancelOrder,
  openIssueTicket,setOpenIssueTicket,
  openVoidTicket,setOpenVoidTicket,
  openManualIssue, setOpenManualIssue,
  openDeny,setOpenDeny,
  openDenyVoid,setOpenDenyVoid,
  openApproveRefund, setOpenApproveRefund,
  openDenyRefund,setOpenDenyRefund,
  openRequestInfo,setOpenRequestInfo,
  openEditRefund,setOpenEditRefund,
  openEditChange, setOpenEditChange,
  openDenyChange, setOpenDenyChange,
  openTicketMoreInfo,setOpenTicketMoreInfo,
  openViewDetail,setOpenViewDetail,
  openApproveTicket,setOpenApproveTicket,
  openApproveChange,setOpenApproveChange,
  openAssignChangeTask,setOpenAssignChangeTask,
  openUpdateChangeRequest,setOpenUpdateChangeRequest,
  openChangeQuote,setOpenChangeQuote,
  openRefundQuote,setOpenRefundQuote,
  openAssignRefund,setOpenAssignRefund,
}) {

  const [loadings,setLoadings] = useState({})
  
  async function handleManualIssue({_id,travelerTicket}) {
    const reqBody = {
        ticketQueueId: _id,
        travelerTicket,
    }
    // return console.log(reqBody)
    setOpenManualIssue({...openManualIssue,loading: true})
    const res = await issueTicketManually(reqBody);
    setOpenManualIssue({...openManualIssue,loading: false})
    if(res.return) {
        enqueueSnackbar('Ticket has been issued.',{variant: 'success'})
        reload && reload()
    } else enqueueSnackbar(res.msg,{variant: 'error'})
  }

  async function handleDeny({_id,reason,...rest}) {
    console.log('request: ',rest)
    const reqBody = {
        ticketQueueId: _id,
        deniedReason: reason,
    }
    setOpenDeny({...openDeny,loading: true})
    const res = await denyTicket(reqBody);
    setOpenDeny({...openDeny,loading: false})

    if(res.return) {
        enqueueSnackbar('Ticket request has been denied.',{variant: 'success'})
        reload && reload()
    } else enqueueSnackbar(res.msg,{variant: 'error'})
  }

  async function handleDenyVoid({_id,reason,...rest}) {
    console.log('request: ',rest)
    const reqBody = {
        ticketQueueId: _id,
        deniedReason: reason,
    }
    setOpenDenyVoid({...openDenyVoid,loading: true})
    const res = await denyVoidRequest(reqBody);
    setOpenDenyVoid({...openDenyVoid,loading: false})

    if(res.return) {
        enqueueSnackbar('Void request has been denied.',{variant: 'success'})
        reload && reload()
    } else enqueueSnackbar(res.msg,{variant: 'error'})
  }

  async function handleDenyRefund({_id,reason,...rest}) {
    console.log('request: ',rest)
    const reqBody = {
        ticketQueueId: _id,
        deniedReason: reason,
    }
    setOpenDenyRefund({...openDenyRefund,loading: true})
    const res = await denyRefundRequest(_id);
    setOpenDenyRefund({...openDenyRefund,loading: false})

    if(res.return) {
        enqueueSnackbar('Refund request has been denied.',{variant: 'success'})
        reload && reload()
    } else enqueueSnackbar(res.msg,{variant: 'error'})
  }

  async function handleDenyChange({_id,reason,...rest}) {
    console.log('request: ',rest)
    // const reqBody = {
    //     ticketQueueId: _id,
    //     deniedReason: reason,
    // }
    setOpenDenyChange({...openDenyChange,loading: true})
    const res = await denyChangeTicketRequest(_id);
    setOpenDenyChange({...openDenyChange,loading: false})

    if(res.return) {
        enqueueSnackbar('Request has been denied.',{variant: 'success'})
        reload && reload()
    } else enqueueSnackbar(res.msg,{variant: 'error'})
}

  async function handleRequestInfo({_id,requestedInfo}) {
    const reqBody = {
        ticketQueueId: _id,
        requestedInfo,
    }
    setOpenRequestInfo({...openRequestInfo,loading: true})
    const res = await requestTicketMoreInfo(reqBody);
    setOpenRequestInfo({...openRequestInfo,loading: false})

    if(res.return) {
        enqueueSnackbar('More info has has been requested.',{variant: 'success'})
        reload && reload()
    } else enqueueSnackbar(res.msg,{variant: 'error'})
  }

  async function handleVoid(id) {
    const res = await voidTicketRequest(id);
    if(res.return) {
        enqueueSnackbar('Ticket has been Void.',{variant: 'success'})
        reload && reload()
    } else enqueueSnackbar(res.msg,{variant: 'error'})
  }
  
  async function handleServiceCharge({_id,refundAmount}) {
    const reqBody = {
        refundRequestId: _id,
        refundAmount: Number(refundAmount),
    }
    setOpenEditRefund({...openEditRefund,loading: true})
    const res = await setRefundFee(reqBody);
    setOpenEditRefund({...openEditRefund,loading: false})

    if(res.return) {
        enqueueSnackbar('Service charge has been set.',{variant: 'success'})
        reload && reload()
    } else enqueueSnackbar(res.msg,{variant: 'error'})
  }

  async function handleChangeFee({_id,changePrice,ticketDifference}) {
    const reqBody = {
        changeOrderId: _id,
        changePrice: Number(changePrice),
        ticketDifference: Number(ticketDifference),
    }
    setOpenEditChange({...openEditChange,loading: true})
    const res = await setChangeFee(reqBody);
    setOpenEditChange({...openEditChange,loading: false})

    if(res.return) {
        enqueueSnackbar('Service charge has been set.',{variant: 'success'})
        reload && reload()
    } else enqueueSnackbar(res.msg,{variant: 'error'})
  }

  async function handleApprove(id) {
    const reqBody = {
        ticketQueueId: id,
    }
    setLoadings({...(loadings||{}),approveTicket: true})
    const res = await approveTicket(reqBody);
    setLoadings({...(loadings||{}),approveTicket: false})
    if(res.return) {
        enqueueSnackbar('Ticket request has been approved.',{variant: 'success'})
        reload && reload()
    } else enqueueSnackbar(res.msg,{variant: 'error'})
  }

  async function handleApproveChange(id) {
    setLoadings({...(loadings||{}),approveChangeTicket: true})
    const res = await approveChangeTicketRequest(id);
    setLoadings({...(loadings||{}),approveChangeTicket: false})
    if(res.return) {
        enqueueSnackbar('Request has been approved.',{variant: 'success'})
        reload && reload()
    } else enqueueSnackbar(res.msg,{variant: 'error'})
  }

  async function handleApproveRefund(id) {
    setLoadings({...(loadings||{}),approveRefundTicket: true})
    const res = await approveRefundTicket(id);
    setLoadings({...(loadings||{}),approveRefundTicket: false})
    if(res.return) {
        enqueueSnackbar('Request has been approved.',{variant: 'success'})
        reload && reload()
    } else enqueueSnackbar(res.msg,{variant: 'error'})
  }

  
  return (
    <div>
      <Modal1 open={openPayment} setOpen={setOpenPayment}>
        <PaymentMethod
          callback={() => {
            reload && reload();
            setOpenPayment(false);
          }}
          flightBookingId={openPayment}
          hide={["booklater"]}
          expand
        />
      </Modal1>

      <CancelOrder
        open={openCancelOrder}
        setOpen={setOpenCancelOrder}
        callback={() => {
          reload && reload();
          setOpenCancelOrder(false);
        }}
      />

      <Modal1 open={openManualIssue} setOpen={setOpenManualIssue}>
        <div className='card p-4 flex flex-col  gap-4 min-w-[300px]'>
            <h5>Ticket Number?</h5>
            <ManualTicketForm travelers={openManualIssue?.flightBooking?.at(0)?.travelers} flightData={openManualIssue}
                onChange={(obj) => setOpenManualIssue({...openManualIssue,travelerTicket: obj})}
            />
            <div className='flex justify-end items-center gap-4'>
                <Button1 variant='outlined' className='btn-outlined flex-1' onClick={() => setOpenManualIssue(false)}>Cancel</Button1>
                <div>
                    <Button1 onClick={() => handleManualIssue(openManualIssue)} loading={openManualIssue?.loading}>Issue Ticket</Button1>
                </div>
            </div>
        </div>
      </Modal1>


      <Modal1 open={openCancelTicket} setOpen={setOpenCancelTicket}>
        <CancelTicket
          data={openCancelTicket}
          callback={() => {
            reload && reload();
            setOpenCancelTicket(false);
          }}
          close={() => setOpenCancelTicket(false)}
        />
      </Modal1>

      <Modal1 open={openIssueTicket} setOpen={setOpenIssueTicket}>
        <IssueTicket
          data={openIssueTicket}
          callback={() => {
            reload && reload();
            setOpenIssueTicket(false);
          }}
          close={() => setOpenIssueTicket(false)}
        />
      </Modal1>

      <Modal3 open={openRequestInfo} setOpen={setOpenRequestInfo}
        title={'Request More Info'}
      >
        <div className='card p-4 flex flex-col  gap-4 min-w-[300px]'>
            {/* <h5>What's missing?</h5> */}
            <TextInput label='Remarks' multiline rows={5} 
                placeholder={'e.g. Phone number is required'}
                value={openRequestInfo?.requestedInfo || ''}
                onChange={(ev) => setOpenRequestInfo({...openRequestInfo,requestedInfo: ev.target.value})} />
            <div className='flex justify-end items-center gap-4'>
                <Button1 variant='outlined' className='btn-outlined flex-1' onClick={() => setOpenRequestInfo(false)}>Cancel</Button1>
                <div>
                    <Button1 onClick={() => handleRequestInfo(openRequestInfo)} loading={openRequestInfo?.loading}>Request more info</Button1>
                </div>
            </div>
        </div>
      </Modal3>

      <Modal3 open={openDeny} setOpen={setOpenDeny}
        title={'Decline Ticket Request'}
      >
        <div className='card p-4 flex flex-col  gap-4 min-w-[300px]'>
            {/* <h5>What's your reson?</h5> */}
            <TextInput label='Remark' multiline rows={5} 
                placeholder={'e.g. Ticket not available'}
                value={openDeny?.reason || ''}
                onChange={(ev) => setOpenDeny({...openDeny,reason: ev.target.value})} />
            <div className='flex justify-end items-center gap-4'>
                <div>
                  <Button1 variant='outlined' className='btn-outlined flex-1' onClick={() => setOpenDeny(false)}>Cancel</Button1>
                </div>
                <Button1 onClick={() => handleDeny(openDeny)} loading={openDeny?.loading}>Decline Request</Button1>
            </div>
        </div>
      </Modal3>

      <Modal1 open={openDenyVoid} setOpen={setOpenDenyVoid}>
        <div className='card p-4 flex flex-col  gap-4 min-w-[300px]'>
            <h5>What's your reson?</h5>
            <TextInput label='' multiline rows={5} 
                placeholder={'e.g. Ticket not available'}
                value={openDenyVoid?.reason || ''}
                onChange={(ev) => setOpenDenyVoid({...openDenyVoid,reason: ev.target.value})} />
            <div className='flex justify-end items-center gap-4'>
                <Button1 variant='outlined' className='btn-outlined flex-1' onClick={() => setOpenDenyVoid(false)}>Cancel</Button1>
                <div>
                    <Button1 onClick={() => handleDenyVoid(openDenyVoid)} loading={openDenyVoid?.loading}>Continue</Button1>
                </div>
            </div>
        </div>
      </Modal1>

      <Modal3 open={openDenyRefund} setOpen={setOpenDenyRefund}
        title={'Decline Refund Request'}
      >
        <div className='card p-4 flex flex-col  gap-4 min-w-[300px]'>
            <h5>What's your reson?</h5>
            <TextInput label='' multiline rows={5} 
                placeholder={'e.g. Ticket not available'}
                value={openDenyRefund?.reason || ''}
                onChange={(ev) => setOpenDenyRefund({...openDenyRefund,reason: ev.target.value})} />
            <div className='flex justify-end items-center gap-4'>
                <Button1 variant='outlined' className='btn-outlined flex-1' onClick={() => setOpenDenyRefund(false)}>Cancel</Button1>
                <div>
                    <Button1 onClick={() => handleDenyRefund(openDenyRefund)} loading={openDenyRefund?.loading}>Continue</Button1>
                </div>
            </div>
        </div>
      </Modal3>

      <Modal3 open={openDenyChange} setOpen={setOpenDenyChange}
        title={'Decline Ticket Re-issue Request'}
      >
        <div className='card p-4 flex flex-col  gap-4 min-w-[300px]'>
            {/* <h5>What's your reson?</h5> */}
            <TextInput label='Reamrk' multiline rows={5} 
                placeholder={'e.g. Ticket not available'}
                value={openDenyChange?.reason || ''}
                onChange={(ev) => setOpenDenyChange({...openDenyChange,reason: ev.target.value})} />
            <div className='flex justify-end items-center gap-4'>
                <div>
                  <Button1 variant='outlined' className='btn-outlined flex-1' onClick={() => setOpenDenyChange(false)}>Cancel</Button1>
                </div>
                <Button1 onClick={() => handleDenyChange(openDenyChange)} loading={openDenyChange?.loading}>Continue</Button1>
            </div>
        </div>
      </Modal3>

      <Modal1 open={openVoidTicket} setOpen={setOpenVoidTicket}>
        <div className='card p-4 flex flex-col  gap-4 min-w-[300px]'>
          <h5>Are you sure you want to void this ticket?</h5>
          <div className='flex justify-end items-center gap-4'>
              <Button1 variant='outlined' className='btn-outlined flex-1' onClick={() => setOpenVoidTicket(false)}>No, Close</Button1>
              <div>
                  <Button1 onClick={() => handleVoid(openVoidTicket)} loading={openVoidTicket?.loading}>Yes, Void Ticket</Button1>
              </div>
          </div>
        </div>
      </Modal1>

      <Modal1 open={openEditRefund} setOpen={setOpenEditRefund}>
        <div className='card p-4 flex flex-col  gap-4 min-w-[300px]'>
            <h5>Refund Amount</h5>
            <TextInput label='' type='number'
                placeholder={'Amount in NGN'}
                value={openEditRefund?.refundAmount || ''}
                onChange={(ev) => setOpenEditRefund({...openEditRefund,refundAmount: ev.target.value})} />
            <div className='flex justify-end items-center gap-4'>
                <Button1 variant='outlined' className='btn-outlined flex-1' onClick={() => setOpenEditRefund(false)}>Close</Button1>
                <div>
                    <Button1 onClick={() => handleServiceCharge(openEditRefund)} loading={openEditRefund?.loading}>Continue</Button1>
                </div>
            </div>
        </div>
      </Modal1>

      <Modal1 open={openEditChange} setOpen={setOpenEditChange}>
          <div className='card p-4 flex flex-col  gap-4 min-w-[300px]'>
              <h5>Refund Amount</h5>
              <TextInput label='Change Fee ' type='number'
                  placeholder={'Amount in NGN'}
                  value={openEditChange?.changePrice || ''}
                  onChange={(ev) => setOpenEditChange({...openEditChange,changePrice: ev.target.value})} />
              <TextInput label='Ticket difference' type='number'
                  placeholder={'Amount in NGN'}
                  value={openEditChange?.ticketDifference || ''}
                  onChange={(ev) => setOpenEditChange({...openEditChange,ticketDifference: ev.target.value})} />
              <div className='flex justify-end items-center gap-4'>
                  <Button1 variant='outlined' className='btn-outlined flex-1' onClick={() => setOpenEditChange(false)}>Close</Button1>
                  <div>
                      <Button1 onClick={() => handleChangeFee(openEditChange)} loading={openEditChange?.loading}>Continue</Button1>
                  </div>
              </div>
          </div>
      </Modal1>

      <Modal3 open={openTicketMoreInfo} setOpen={setOpenTicketMoreInfo}>
        Test
      </Modal3>

      <Modal3 open={openViewDetail} setOpen={setOpenViewDetail}
        title={'View Booking Details'}
      >
        <TicketManagementFlightOrderDetail fetchId={openViewDetail?.id} page={openViewDetail?.page} />
      </Modal3>

      <Modal3 open={openApproveTicket} setOpen={setOpenApproveTicket}
        title={'Confirm Ticket Issuance'}
      >
        <div className="max-w-[500px]">
          <div className="p-4">
            <p>Are you sure you want to approve the issuance of this ticket?  This action cannot be undone.</p>
          </div>
          <div className="flex gap-4">
            <Button1 variant='text' onClick={() => setOpenApproveTicket(false)}>Cancel</Button1>
            <Button1 loading={loadings?.approveTicket} onClick={() => handleApprove(openApproveTicket)}>Confirm</Button1>
          </div>
        </div>
      </Modal3>

      <Modal3 open={openApproveChange} setOpen={setOpenApproveChange}
        title={'Confirm Re-Issue Request Approval'}
      >
        <div className="max-w-[500px] p-4">
          <div className="p-4">
            <p>Are you sure you want to approve the re-issue of this ticket? This action will override the current ticket details.</p>
          </div>
          <div className="flex gap-4">
            <Button1 variant='text' onClick={() => setOpenApproveChange(false)}>Cancel</Button1>
            <Button1 loading={loadings?.approveChangeTicket} onClick={() => handleApproveChange(openApproveChange)}>Confirm</Button1>
          </div>
        </div>
      </Modal3>

      <Modal3 open={openApproveRefund} setOpen={setOpenApproveRefund}
        title={'Confirm Refund Request Approval'}
      >
        <div className="max-w-[500px] p-4">
          <div className="p-4">
            <p>Are you sure you want to approve this refund request? This action will process the refund and cannot be undone.</p>
          </div>
          <div className="flex gap-4">
            <Button1 variant='text' onClick={() => setOpenApproveRefund(false)}>Cancel</Button1>
            <Button1 loading={loadings?.approveRefundTicket} onClick={() => handleApproveRefund(openApproveRefund)}>Confirm Refund</Button1>
          </div>
        </div>
      </Modal3>


      <Modal3 open={openAssignChangeTask} setOpen={setOpenAssignChangeTask}
        title={'Assign Task'}
      >
        <div className="p-4 max-w-[700px]">
          <AssignChange callback={reload} data={openAssignChangeTask} />
        </div>
      </Modal3>

      <Modal3 open={openUpdateChangeRequest} setOpen={setOpenUpdateChangeRequest}
        title={'Add an update'}
      >
        <div className='card p-4 flex flex-col  gap-4 md:min-w-[500px]'>
            {/* <h5>What's missing?</h5> */}
            <div className="flex gap-2">
              <p>Re-issue Request Time: </p>
              <span>{moment(openUpdateChangeRequest?.updatedAt).format('YYYY-MM-DD, HH:mm')}</span>
            </div>
            <TextInput label='Remarks' multiline rows={5} 
                // placeholder={'e.g. Phone number is required'}
                value={openUpdateChangeRequest?.requestedInfo || ''}
                onChange={(ev) => setOpenUpdateChangeRequest({...openUpdateChangeRequest,requestedInfo: ev.target.value})} />
            <div className='flex justify-end items-center gap-4'>
                <div>
                  <Button1 variant='outlined' className='btn-outlined flex-1' onClick={() => setOpenUpdateChangeRequest(false)}>Cancel</Button1>
                </div>
                <Button1 onClick={() => console.log('Nate: No Route')} loading={openUpdateChangeRequest?.loading}>Add Update</Button1>
            </div>
        </div>
      </Modal3>

      <Modal3 open={openChangeQuote} setOpen={setOpenChangeQuote}
        title={'Quote'}
      >
        <div className="p-4 max-w-[700px] md:min-w-[500px]">
          <ChangeQuote data={openChangeQuote} reload={reload} />
        </div>
      </Modal3>

      <Modal3 open={openRefundQuote} setOpen={setOpenRefundQuote}
        title={'Quote'}
      >
        <div className="p-4 max-w-[700px] md:min-w-[500px]">
          <RefundQuote data={openRefundQuote} reload={reload} />
        </div>
      </Modal3>

      <Modal3 open={openAssignRefund} setOpen={setOpenAssignRefund}
        title={'Assign Task'}
      >
        <div className="p-4 max-w-[700px]">
          <AssignRefund callback={reload} data={openAssignRefund} />
        </div>
      </Modal3>

      {/* <Modal1 open={openRebook} setOpen={setOpenRebook}>
        <Rebook data={openRebook} />
      </Modal1> */}
      
    </div>
  )
}

export default OrderList;

const Order = ({ order, og }) => {
  const bookingData = og?.flightBooking?.at(0);
  const [open, setOpen] = useState(false);
  return (
    <div className="w-full flex  justify-between bg-white p-4 gap-3">
      <div className="flex flex-col md:flex-row justify-between flex-1">
        {order?.map(({ elements, width }, index) => (
          <div
            className={`w-full ${width || "flex-1"} ${
              !open && index !== 0 ? "hidden md:flex" : "flex"
            } flex-col ${open && "border-b pb-3"} mb-2 px-2 md:border-b-0 md:pb-0`}
            key={index}
          >
            {elements?.map((element, idx) => (
              <OrderContent element={element} key={idx} />
            ))}
          </div>
        ))}
      </div>
      <div className={`${bookingData?.status || og?.status ? 'flex':'hidden'} flex-col justify-between items-center `}>
        <Menu og={og} order={order} />
        <div className="flex md:hidden" onClick={() => setOpen(!open)}>
          {open ? (
            <img
              src="/IconChevronUp.svg"
              alt=""
              className="w-5 object-contain"
            />
          ) : (
            <img
              src="/IconChevronDown.svg"
              alt=""
              className="w-5 object-contain"
            />
          )}
        </div>
      </div>
    </div>
  );
};

const OrderContent = ({ element }) => {
  if(element?.render)
    return element?.render();
  
  switch (element.type) {
    case "title":
      return <Title value={element.value} />;
    case "copy":
      return <Copy value={element.value} render={element?.renderValue} />;
    case "tag":
      return <Tag element={element} />;
    case "time":
      return <Time value={element?.value} />;
    case "status":
      return <Status value={element?.value} />;
    default:
      return <Text value={element.value} />;
  }
};

const Text = ({ value }) => {
  return <span className="text-sm mb-2">{value}</span>;
};

const Title = ({ value }) => {
  return <span className="text-sm text-[#6D7C94] mb-2">{value}</span>;
};

const Copy = ({ value, render }) => {
  const { enqueueSnackbar } = useSnackbar();
  const handleCopy = () => {
    try {
      navigator.clipboard.writeText(value);
      enqueueSnackbar("Copied to clipboard!", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Failed to copy to clipboard!", { variant: "error" });
    }
  };
  return (
    <div
      className="flex gap-1 items-center mb-2 cursor-pointer"
      onClick={handleCopy}
    >
      <span className="text-xs text-primary1">{render ? render() : value}</span>
      <img src="/IconCopy.svg" alt="" />
    </div>
  );
};

const Tag = ({ element }) => {
  return (
    <div className={`rounded-md `}>
      <span
        className={`${element?.color} ${element?.background} text-xs font-bold px-2 py-1 rounded-md ${element?.className}`}
      >
        {element?.value}
      </span>
    </div>
  );
};

const Time = ({ value }) => {
  return (
    <div className="flex gap-1 items-center mb-2 cursor-pointer">
      <img src="/IconClock1.svg" alt="" />
      <span className="text-xs">{value}</span>
    </div>
  );
};

const Status = ({ value }) => {
  return (
    <div className={`rounded-md mb-3`}>
      <span
        className={`bg-[#FDF3D7] text-[#6B5618] text-xs font-bold px-2 py-1 rounded-md `}
      >
        {value}
      </span>
    </div>
  );
};

const Menu = ({ order, og }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const {ticketType} = useSelector(state => state.order);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (open) {
        setTimeout(() => {
          setOpen(false);
        }, 400);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  const handleSelect = () => {
    navigate("/order/flight/ticket/detail");
  };

  const menu = () => {
    let menus = ["Cancel order", "Open order", "Refund"]
    return menus
  };

  const MenuType = () => {
    switch(ticketType) {
      case 'Flights':
        return <FlightMenu og={og} />
      default:
        <div className="absolute py-2 flex flex-col bg-white rounded-md right-6 top-4 shadow-md min-w-[200px]">
          {menu()?.map((item,index) => (
            <div key={index}>
              {item}
            </div>
          ))}
        </div>
    }
  }

  return (
    <div className="cursor-pointer p-2 relative " onClick={() => setOpen(true)}>
      <img src="/IconMore.svg" alt="" className="h-5 object-contain relative" />
      <div className="">
        {open && 
          <MenuType />
        }
      </div>
    </div>
  );
};

function FlightMenu({og}) {
  const [openPayment,setOpenPayment] = useState(false);
  const bookingData = og?.flightBooking.at(0);
  const {ticketFilterType} = useSelector(state => state.order)

  const voidTime = new Date(bookingData?.voidDeadline || null)

  const navigate = useNavigate();
  
  if(getTestLevel() === getTestLevel('dev'))
    console.log('og: ',og)
  
  return (
    <div className="absolute p-2 flex flex-col z-10 bg-white rounded-md right-2 top-full shadow-xl min-w-[200px]"
     style={{
      boxShadow: '0 5px 15px #999'
     }}
    >
      <ActionContext.Consumer>
        {(value) => {
          const {
            reload,
            setOpenPayment,
            setOpenCancelOrder,
            setOpenIssueTicket,
            setOpenRebook,
            setOpenVoidTicket,
            setOpenCancelTicket,
            refreshTicket,
            issue, manualIssue,
            approve, deny,
            requestInfo,
            approveVoid, denyVoid,
            approveRefund, denyRefund, setOpenEditRefund, refundTicket,
            approveChange, denyChange, setOpenEditChange,
            setOpenViewDetail,
            assignChangeTask,
            setOpenUpdateChangeRequest,
            setOpenChangeQuote,
            setOpenRefundQuote,
          } = value || {}
          // Ticketed:
          // x View order
          // x Add a bag
          // x Change date
          // x Change name
          // Void
          // x Refund (if refundable)

          // Pending Payment:
          // View order
          // Make payment
          // Hold booking
          // Add hotel
          // Add tour
          // Add insurance 
          // Add bag
          // Add a seat
          // Cancel order

          // PAID:
          // View order
          // Issue ticket
          // Add hotel
          // Add tour
          // Add insurance 
          // Add bag
          // Add a seat
          // Cancel order
        
          // Cancelled:
          // View order
          // Rebook order
          
          // Share
          // Share pdf
          // Share via email

          const postTicketing = og?.fee !== undefined || og?.ticketNo || og?.changeRequest;

          const menus = [
            {
              label: postTicketing ? 'View Request' : "View order",
              action: () => setOpenViewDetail({id: og?._id,page: ticketFilterType}),
              // action: () => navigate("/order/flight/ticket/detail/"+og?._id+'?page='+ticketFilterType),
            },
            {
              label: 'Refresh Status',
              action: () => refreshTicket(bookingData?._id),
              showFor: ['PendingVoid','WaitingConfirmation']
            },
          ]

          if(ticketFilterType === 'Tickets') {
            menus?.push(...[
              {
                label: 'Issue Ticket',
                action: () => issue(og?._id),
                showFor: ['Pending','RequestedInfo']
              },
              {
                label: 'Manual Issue',
                action: () => manualIssue(og),
                // showFor: ['Pending']
              },  
              {
                label: 'Cancel Ticket',
                action: () => setOpenCancelTicket(bookingData),
                showFor: ['Ticket issued','issued','Completed']
              },
              {
                label: 'Approve',
                action: () => approve(og?._id),
                showFor: ['Open']
              },
              {
                label: 'Deny',
                action: () => deny(og),
                showFor: ['Open']
              },
              {
                label: 'Request more info',
                action: () => requestInfo(og),
                showFor: ['Open']
              },
  
            ])
          }
          else if(ticketFilterType === 'Void Management') {
            menus?.push(...[
              {
                label: 'Approve',
                action: () => approveVoid(og?._id),
                showFor: ['Pending']
              },
              {
                label: 'Deny',
                action: () => denyVoid(og),
                showFor: ['Pending']
              },
              {
                label: 'Void Ticket',
                action: () => setOpenVoidTicket(og?._id),
                showFor: ['Approved']
              },  
            ])
          }
          else if(ticketFilterType === 'Refund Management') {
            menus?.push(...[
              {
                label: 'Approve',
                action: () => approveRefund(og?._id),
                showFor: ['Pending'],
                hide: !og?.isSubmitted,
              },
              {
                label: 'Edit Refund',
                action: () => setOpenEditRefund(og),
                showFor: ['Approved'],
                hide: og?.confirmed === true,
              },  
              {
                label: 'Quote',
                action: () => setOpenRefundQuote(og),
                showFor: ['Pending']
              },  
              {
                label: 'Assign',
                action: () => refundTicket(og),
                showFor: ['Approved','Pending']
              },  
              {
                label: 'Deny',
                action: () => denyRefund(og),
                showFor: ['Pending']
              },
            ])
          }
          else if(ticketFilterType === 'Re-issue Management') {
            menus?.push(...[
              {
                label: 'Approve',
                action: () => approveChange(og?._id),
                showFor: ['Pending']
              },
              {
                label: 'Update Request',
                action: () => setOpenUpdateChangeRequest(og),
                showFor: ['Pending']
              },
              {
                label: 'Edit Change Fee',
                action: () => setOpenEditChange(og),
                showFor: ['Pending']
              },  
              {
                label: 'Create a quote',
                action: () => setOpenChangeQuote(og),
                showFor: ['Pending','Approved']
              },  
              // {
              //   label: 'Reissue Ticket',
              //   action: () => assignChangeTask(og),
              //   showFor: ['Approved']
              // },  
              {
                label: 'Assign Task',
                action: () => assignChangeTask(og),
                showFor: ['Pending','Approved']
              },
              {
                label: 'Decline',
                action: () => denyChange(og),
                showFor: ['Pending']
              },
            ])
          }
          
          if(!postTicketing)
            menus?.push(...[
              {
                label: 'Make payment',
                action: () => setOpenPayment(og?.flightObj?._id),
                showFor: ['Not Paid','booked']
              },
              {
                label: 'Issue Ticket',
                action: () => setOpenIssueTicket(bookingData),
                showFor: ['Paid','issuable']
              },
              {
                label: 'Hold booking',
                action: () => {},
                showFor: ['Not Paid','booked']
              },
              {
                label: 'Add a bag',
                action: () => {},
                showFor: ['Not Paid','booked','Ticket issued','issued','Paid','issuable']
              },
              {
                label: 'Add a seat',
                action: () => {},
                showFor: ['Not Paid','booked','Ticket issued','issued','Paid','issuable']
              },
              {
                label: 'Add hotel',
                action: () => {},
                showFor: ['Not Paid','booked','Paid','issuable']
              },
              {
                label: 'Add tour',
                action: () => {},
                showFor: ['Not Paid','booked','Paid','issuable']
              },
              {
                label: 'Add insurance',
                action: () => {},
                showFor: ['Not Paid','booked','Paid','issuable']
              },
              {
                label: 'Rebook',
                action: () => setOpenRebook(og?.flightObj),
                showFor: ['Expired','Canceled','Ticket Canceled']
              },
              // {
              //   label: 'Cancel Ticket',
              //   action: () => setOpenCancelTicket(bookingData),
              //   showFor: ['Ticket issued','issued','Completed']
              // },
              {
                label: 'Change date',
                action: () => navigate('/order/flight/ticket/changedate/'+og?._id),
                showFor: ['Ticket issued','issued']
              },
              {
                label: 'Change name',
                action: () => navigate('/order/flight/ticket/changeinfo/'+og?._id),
                showFor: ['Ticket issued','issued']
              },
              {
                label: 'Refund',
                action: () => navigate('/order/flight/ticket/refund/'+og?._id),
                showFor: ["Ticket issued", "issued"],
                hide: og?.status === 'Approved'//bookingData?.isRefundable
              },
              {
                label: 'Download pdf',
                action: () => {},
              },
              {
                label: 'Share order',
                action: () => {},
              },
              {
                label: 'Share pnr',
                action: () => {},
                hide: !isGDSSupplier(bookingData?.supplier)
              },
              {
                label: 'Issue via intraverse',
                action: () => {},
                hide: !isGDSSupplier(bookingData?.supplier)
              },
              {
                label: 'Cancel order',
                action: () => setOpenCancelOrder(og?.flightObj?._id),
                showFor: ["Not Paid", "booked","Paid",'issuable']
              },
              {
                label: 'Void',
                action: () => navigate('/order/flight/ticket/void/'+og?._id),
                hide: voidTime - new Date() <= 0,
                showFor: ["Ticket issued", "issued","Denied"],
              },
            ])
      
          let items = menus?.filter(obj => {
            const bookingData = og?.flightBooking?.at(0)
            const status = flightStatusMap(og?.status || bookingData?.status);
            console.log(status,obj?.showFor)
            return (obj?.showFor ? obj?.showFor?.includes(status) : true) && !obj?.hide
          })
          
          return (
            (items||[])?.map((item, index) => (
              <div
                key={index}
                onClick={() => item?.action()}
                className="font-bold text-sm text-[#444] px-2 py-2 hover:bg-[#818083]/10 border-b"
              >
                {item?.label}
              </div>
            ))
          )
        }}
      </ActionContext.Consumer>

    </div>
  )
}